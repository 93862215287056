import Vue from 'vue';

import Vuetify from 'vuetify';
import VueMasonry from 'vue-masonry-css';
import InfiniteLoading from 'vue-infinite-loading';
import VueResource from 'vue-resource';
import VueImg from 'v-img';
import BootstrapVue, {BootstrapVueIcons} from 'bootstrap-vue';
import shareIt from "vue-share-it";

import './../../vuejs/vue-go-top.min';

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
Vue.use(BootstrapVueIcons)
Vue.use(shareIt);

function vueInit(elementId, options) {
    const element = document.getElementById(elementId);
    if (element) {
        options.el = element;
        new Vue(options).$mount('#'.elementId);
    }
}

const messages = {
    fr: {
        message: {
            buttons: {
                readAds: "Lire l'annonce",
                explore: "Explorer le book",
                search: "Rechercher",
                save: "Enregistrer",
                send: "Envoyer",
            },
            publish: {
                image: "{count} photo | {count} photo | {count} photos",
                gallery: "{count} galerie | {count} galerie | {count} galeries",
            },
            ads: {
                search: {
                    title: "Qui me recherche ?",
                    fields: {
                        job: {
                            label: "Je suis",
                            hint: "Choisissez une profession.",
                        },
                        sex: {
                            label: "Je suis de sexe",
                            hint: "Ex : Homme, Femme, sans aucune importance",
                        },
                        prestation: {
                            label: "Type de prestation",
                            hint: "Ex : Collaboration, projet rémunéré",
                        },
                        location: {
                            label: "Je me situe",
                            hint: "Indiquez un lieu. Ex : Bordeaux, France",
                            placeHolder: "Commencez à saisir votre ville, puis sélectionnez parmi les propositions qui apparaîtront.",
                        }
                    },
                    notFound: "Aucune annonce pour l'instant. Étonnant! Pourriez-vous réessayer avec d'autre critère ?"
                },
                head: {
                    title: "Le coin des petites annonces",
                    content: "Découvrez toutes les annonces publiées par les artistes de la communauté Bookfolio ! Vous cherchez de nouvelles idées ? Consultez notre catalogue d'annonces pour dénicher l'inspiration qui vous manque.",
                    buttons: {
                        add: "Postez une annonce",
                        read: "Lire l'annonce",
                    }
                },
            },
            feedback: {
                search: {
                    title: "Filtre des idées",
                    fields: {
                        sort: {
                            label: "Trier par",
                            hint: "Choisissez une option.",
                        },
                        category: {
                            label: "Catégorie",
                            hint: "Ex : Fonctionnalité, Amélioration",
                        },
                        status: {
                            label: "Statut",
                            hint: "Ex : Publié, Développé",
                        },
                    },
                    notFound: "Aucune idée pour l'instant."
                },
                head: {
                    title: "Boîte à idées",
                    content: "Vous pouvez nous faire part publiquement de vos idées ou vos souhaits. En cliquant sur l'icône en forme de cœur, vous pouvez voter pour les idées proposées par les autres utilisateurs. Plus une idée obtient de votes, plus elle aura de chances d'être prise en considération pour être développée. Nous sommes à l'écoute de vos retours et de vos suggestions pour améliorer nos services.",
                    buttons: {
                        add: "Proposez une idée",
                        read: "Voir l'idée",
                    }
                },
            },
            directory: {
                filters: {
                    empty: "No artists at this time.",
                    experience: {
                        label: "Expérience",
                        hint: "Choisissez une expérience.",
                    },
                    location: {
                        label: "Où ?",
                        hint: "Indiquez un lieu. Ex : Bordeaux, France",
                        empty: "Commencer à saisir un lieu, puis sélectionner.",
                    },
                    gender: {
                        label: "Sexe",
                        hint: "Homme, femme ou sans importance.",
                    },
                    sort: {
                        label: "Trier par",
                    },
                    advancedSearch: {
                        title: "Recherche avancée",
                        origin: {
                            label: "Origine ethnique",
                            hint: "Ex: Européen(e), asiatique, etc.",
                        },
                        hairColor: {
                            label: "Couleur des cheveux",
                            hint: "Ex: Blonds, bruns, etc.",
                        },
                        EyesColor: {
                            label: "Couleur des yeux",
                            hint: "Ex: Noirs, marrons, etc.",
                        },
                        mensurations: {
                            title: "Mensurations",
                            between: "Entre",
                            and: "et",
                            size: {
                                title: "Taille",
                                hint: "Définissez une taille",
                            },
                            weight: {
                                title: "Poids",
                                hint: "Définissez un poids",
                            },
                            hip: {
                                title: "Tour de hanche",
                                hint: "Définissez un tour de hanche",
                            },
                            confection: {
                                title: "Confection",
                                hint: "Définissez une confection",
                            },
                            pointure: {
                                title: "Pointure",
                                hint: "Définissez une pointure",
                            }
                        }
                    }
                }
            },
            search: {
                title: "Qui recherchez-vous ?",
                profession: {
                    label: "Profession",
                    placeholder: "Type d'artistes"
                },
                experience: {
                    label: "Expérience",
                    placeholder: "Quelle est son expérience ?"
                },
                location: {
                    label: "Localisation",
                },
                advancedSearch: {
                    title: "Recherche avancée",
                    style: {
                        label: "Style de photos",
                        placeholder: "Sans importance"
                    },
                    ethnicity: {
                        label: "Origine ethnique",
                        placeholder: "Sans importance"
                    },
                    hairColor: {
                        label: "Couleur des cheveux",
                        placeholder: "Sans importance"
                    },
                    eyesColor: {
                        label: "Couleur des yeux",
                        placeholder: "Sans importance"
                    },
                    sexe: {
                        label: "Sexe",
                        placeholder: "Sans importance"
                    },

                },
                results: {
                    one: "résultat",
                    many: "résultats",
                },
                filters: {
                    hideEmpty: {
                        label: "Masquer les books sans contenu",
                        placeholders: {
                            yes: "Oui",
                            no: "Non"
                        }
                    },
                    sortBy: {
                        label: "Trier par :",
                    }
                }
            },
            alerts: {
                availability: {
                    success: {
                        content: "Votre demande a été envoyée avec succès ! Veuillez rester attentif à vos e-mails."
                    }
                }
            },
            availabilities: {
                refine_your_search: "Affinez votre recherche",
                what_type_of_artist_are_you_looking_for: "QUEL TYPE D'ARTISTE RECHERCHEZ-VOUS ?",
                select_artist_type: "Sélectionnez un type d'artiste",
                what_opportunities_do_you_offer: "Quels genres d'opportunités proposez-vous ?",
                in_which_city: "Dans quelle ville ?",
                for_which_date: "Pour quelle date ?",
                find_perfect_talent_for_event: "Trouvez le talent parfait pour votre événement",
                discover_current_artist_availabilities: "Découvrez les disponibilités des artistes du moment. Que vous recherchiez un modèle, un musicien, un peintre, un photographe, une danseuse ou tout autre talent artistique, utilisez nos filtres de recherche pour trouver des artistes disponibles selon vos critères de date et lieu.",
                what_about_you_what_are_your_availabilities: "Et vous, quelles sont vos disponibilités ?",
                add_your_availabilities: "Ajouter vos disponibilités",
                project_type: "Type de projet",
                learn_more: "En savoir plus",
                select_opportunity: "Sélectionnez une opportunité",
                make_a_choice: "Faites un choix",
                is_it_paid_or_free: "Est-elle rémunérée ou gratuite ?",
                no_availabilities_at_the_moment: "Aucune disponibilité pour l'instant. Merci de revenir un peu plus tard :)",
                paid: "Rémunérée",
                free: "Gratuite",
                from_date: "Du",
                to: "au",
                hide_empty_books: "Masquer les books sans contenu",
                availability: "disponibilité(s)"
            },
            location: "Localisation",
            for_text: "Pour",
            gender: "de sexe",
            service_type: "Type de prestation",
            all_photos: "toutes les photos"
        }
    },
    en: {
        message: {
            buttons: {
                readAds: "Read ad",
                explore: "Explore the portfolio",
                save: "Save",
                send: "Send",
            },
            publish: {
                image: "{count} photo | {count} photo | {count} photos",
                gallery: "{count} gallery | {count} gallery | {count} galleries",
            },
            ads: {
                search: {
                    title: "Who is looking for me?",
                    fields: {
                        job: {
                            label: "I am",
                            hint: "Choose a job.",
                        },
                        sex: {
                            label: "I am of sex",
                            hint: "Ex: Man, Woman, without any importance",
                        },
                        prestation: {
                            label: "Type of service",
                            hint: "Ex: Collaboration, paid project",
                        },
                        location: {
                            label: "I'm at",
                            hint: "Specify a location. Ex: Bordeaux, France",
                            placeHolder: "Start typing your city, then select from the list that appears.",
                        }
                    },
                    notFound: "No announcements yet. Troubler! Could you try again with another criterion?"
                },
                head: {
                    title: "The classifieds corner",
                    content: "Find all the announcements posted by the artists of the Bookfolio community. Do you have a new project?",
                    buttons: {
                        add: "Post an ad",
                        read: "Read the ad",
                    }
                },
            },
            feedback: {
                search: {
                    title: "Ideas filter",
                    fields: {
                        sort: {
                            label: "Sort by",
                            hint: "Choose an option.",
                        },
                        category: {
                            label: "Category",
                            hint: "Ex: Functionality, Improvement",
                        },
                        status: {
                            label: "Status",
                            hint: "Ex: Published, Developed",
                        },
                    },
                    notFound: "No idea for now."
                },
                head: {
                    title: "Suggestion box",
                    content: "You can share your ideas or wishes with us publicly. By clicking on the heart icon, you can vote for ideas submitted by other users. The more votes an idea gets, the more likely it is to be considered for development. We are listening to your feedback and your suggestions to improve our services.",
                    buttons: {
                        add: "Suggest an idea",
                        read: "See the idea",
                    }
                },
            },
            directory: {
                filters: {
                    empty: "No artists at this time.",
                    experience: {
                        label: "Experience",
                        hint: "Choose an experience.",
                    },
                    location: {
                        label: "Where",
                        hint: "Specify a location. Ex: Bordeaux, France",
                        empty: "Start typing a location, then select.",
                    },
                    gender: {
                        label: "Gender",
                        hint: "Male, female or non-binary/non-conforming.",
                    },
                    sort: {
                        label: "Sort by",
                    },
                    advancedSearch: {
                        title: "Advanced search",
                        origin: {
                            label: "Ethnicity",
                            hint: "Ex: European, Asian, etc.",
                        },
                        hairColor: {
                            label: "Hair color",
                            hint: "Ex: Blondes, brunettes, etc.",
                        },
                        EyesColor: {
                            label: "Eye colour",
                            hint: "Ex: Blacks, browns, etc.",
                        },
                        mensurations: {
                            title: "Measurements",
                            between: "Between",
                            and: "and",
                            size: {
                                title: "Size",
                                hint: "Define a size",
                            },
                            weight: {
                                title: "Weight",
                                hint: "Define a weight",
                            },
                            hip: {
                                title: "Hip circumference",
                                hint: "Define a hip circumference",
                            },
                            confection: {
                                title: "Confection",
                                hint: "Define a confection",
                            },
                            pointure: {
                                title: "Shoe size",
                                hint: "Define a shoe size",
                            }
                        }
                    }
                }
            },
            search: {
                title: "Who are you looking for?",
                profession: {
                    label: "Type of portfolio",
                    placeholder: "What kind of artists are you looking for?"
                },
                experience: {
                    label: "Experience",
                    placeholder: "What is his experience?"
                },
                location: {
                    label: "Location",
                },
                advancedSearch: {
                    title: "Advanced search",
                    style: {
                        label: "Photo style",
                        placeholder: "Not important"
                    },
                    ethnicity: {
                        label: "Ethnicity",
                        placeholder: "Not important"
                    },
                    hairColor: {
                        label: "Hair color",
                        placeholder: "Not important"
                    },
                    eyesColor: {
                        label: "Eyes color",
                        placeholder: "Not important"
                    },
                    sexe: {
                        label: "Sex",
                        placeholder: "Not important"
                    },

                },
                results: {
                    one: "result",
                    many: "results",
                },
                filters: {
                    hideEmpty: {
                        label: "Hide books without content",
                        placeholders: {
                            yes: "Yes",
                            no: "No"
                        }
                    },
                    sortBy: {
                        label: "Sort by :",
                    }
                }
            },
            alerts: {
                availability: {
                    success: {
                        content: "Your request has been sent successfully! Please be mindful of your emails."
                    }
                }
            },
            availabilities: {
                refine_your_search: "Refine your search",
                what_type_of_artist_are_you_looking_for: "WHAT TYPE OF ARTIST ARE YOU LOOKING FOR?",
                select_artist_type: "Select an artist type",
                what_opportunities_do_you_offer: "What genres of opportunities do you offer?",
                in_which_city: "In which city?",
                for_which_date: "For which date?",
                find_perfect_talent_for_event: "Find the perfect talent for your event",
                discover_current_artist_availabilities: "Discover the availabilities of current artists. Whether you're looking for a model, musician, painter, photographer, dancer, or any other artistic talent, use our search filters to find available artists based on your date and location criteria.",
                what_about_you_what_are_your_availabilities: "And you, what are your availabilities?",
                add_your_availabilities: "Add your availabilities",
                project_type: "Project type",
                learn_more: "Learn more",
                select_opportunity: "Select an opportunity",
                make_a_choice: "Make a choice",
                is_it_paid_or_free: "Is it paid or free?",
                no_availabilities_at_the_moment: "No availabilities at the moment. Please come back later :)",
                paid: "Paid",
                free: "Free",
                from_date: "From",
                to: "to",
                hide_empty_books: "Hide empty books",
                availability: "availability(ies)"
            },
            location: "Location",
            for_text: "For",
            gender: "of gender",
            service_type: "Service Type",
            all_photos: "all photos"
        },
    }
}

const i18n = new VueI18n({
    lazy: true,
    locale: 'fr',
    messages,
})

Vue.use(Vuetify);
Vue.use(GoTop);
Vue.use(VueMasonry);
Vue.use(InfiniteLoading);
Vue.use(VueResource);
Vue.use(VueImg);
Vue.use(BootstrapVue)

import frontImagesHome from '../components/front/frontImagesHome.vue';
import inspirations from '../components/front/inspirations.vue';
import annuaireFilter from '../components/front/annuaireFilter.vue';
import newPortfolios from '../components/front/newPortfolios.vue';
import annonces from '../components/front/annonces.vue';
import availabilities from '../components/front/availabilities.vue';

vueInit("front_images_home", {
    vuetify: new Vuetify(),
    GoTop,
    VueMasonry,
    VueResource,
    VueImg,
    render: h => h(frontImagesHome)
})

vueInit("inspirations", {
    vuetify: new Vuetify(),
    GoTop,
    VueMasonry,
    VueResource,
    VueImg,
    render: h => h(inspirations)
})

import VueRouter from 'vue-router'

const routes = [{
    path: '/',
},
];
const router = new VueRouter
({
    mode: 'history',
    routes
})
Vue.use(VueRouter);

import VueFormulate from '@braid/vue-formulate'

Vue.use(VueFormulate, {
    useInputDecorators: false,
    classes: {
        input(context) {
            if (context.type === 'radio') {
                return 'form-check-input me-3'
            }
        },
        label(context) {
            switch (context.classification) {
                case 'box':
                    return 'form-check-label';
                default:
                    return '';
            }
        },
        //outer: 'mytheme-wrapper',
    }
})
vueInit("annuaireFilter", {
    vuetify: new Vuetify(),
    GoTop,
    VueMasonry,
    VueResource,
    VueImg,
    BootstrapVue,
    i18n,
    router,
    VueFormulate,
    render: h => h(annuaireFilter)
})

vueInit("newPortfolios", {
    vuetify: new Vuetify(),
    GoTop,
    VueMasonry,
    VueResource,
    VueImg,
    BootstrapVue,
    i18n,
    router,
    VueFormulate,
    render: h => h(newPortfolios)
})

vueInit("annonces", {
    vuetify: new Vuetify(),
    GoTop,
    VueResource,
    BootstrapVue,
    i18n,
    render: h => h(annonces)
})


vueInit("availabilities", {
    vuetify: new Vuetify(),
    GoTop,
    VueResource,
    BootstrapVue,
    i18n,
    render: h => h(availabilities)
})


import Notifications from 'vue-notification'

Vue.use(Notifications)

import suggestionBox from '../components/front/suggestionBox.vue';

vueInit("suggestion_box", {
    vuetify: new Vuetify(),
    GoTop,
    VueResource,
    BootstrapVue,
    i18n,
    Notifications,
    render: h => h(suggestionBox)
})

import vueButtonVote from '../components/front/vueButtonVote.vue';
import axios from "axios";
import WidgetVueAvailability from "../components/dashboard/widgets/WidgetVueAvailability";

vueInit("vueButtonVote", {
    vuetify: new Vuetify(),
    VueResource,
    BootstrapVue,
    i18n,
    Notifications,
    render: h => h(vueButtonVote, {
        props: {
            countVotes: document.getElementById("vueButtonVote").getAttribute("countVotes"),
            feedbackId: document.getElementById("vueButtonVote").getAttribute("feedbackId"),
            isLoading: document.getElementById("vueButtonVote").getAttribute("isLoading"),
            liked: document.getElementById("vueButtonVote").getAttribute("liked"),
            isOwner: document.getElementById("vueButtonVote").getAttribute("isOwner"),
            isUserLoggedIn: document.getElementById("vueButtonVote").getAttribute("isUserLoggedIn"),
            isCompleted: document.getElementById("vueButtonVote").getAttribute("isCompleted")
        }
    })
})

import feedImages from '../components/front/feedImages.vue';

vueInit("app-feed-images", {
    vuetify: new Vuetify(),
    GoTop,
    VueMasonry,
    VueResource,
    VueImg,
    BootstrapVue,
    i18n,
    router,
    BootstrapVueIcons,
    render: h => h(feedImages,{
        props: {
            profession: document.getElementById("datas").getAttribute("profession"),
        }
    })
})





