var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product3-grid full w-dyn-items", attrs: { role: "list" } },
    [
      _c("go-top", {
        attrs: {
          "bg-color": "#36b5aa",
          src: "/assets/img/icon-backtotop.png",
          size: 50,
          "has-outline": false,
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.images, function (image, $index) {
        return _c(
          "div",
          {
            key: $index,
            staticClass: "margin-bottom-20 w-dyn-item",
            attrs: {
              id: "w-node-_767e1df4-be0f-71aa-0c2e-785666f70451-bddc40f2",
              "data-w-id": "767e1df4-be0f-71aa-0c2e-785666f70451",
              role: "listitem",
            },
          },
          [
            _c(
              "div",
              { staticClass: "image-wrap" },
              [
                _c(
                  "a",
                  {
                    staticClass: "label white-label",
                    attrs: { href: image.user.book, target: "_blank" },
                  },
                  [_vm._v(_vm._s(image.user.fullName))]
                ),
                _vm._v(" "),
                _c("v-img", {
                  directives: [
                    {
                      name: "img",
                      rawName: "v-img",
                      value: { src: image.path, title: image.title },
                      expression: "{ src: image.path, title: image.title }",
                    },
                  ],
                  staticClass: "grey lighten-2 image-full",
                  attrs: {
                    loading: "lazy",
                    alt: image.title,
                    src: image.thumb_path,
                    "lazy-src": image.thumb_path,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setview(image.id)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "placeholder",
                        fn: function () {
                          return [
                            _c(
                              "v-row",
                              {
                                staticClass: "fill-height ma-0",
                                attrs: { align: "center", justify: "center" },
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "grey lighten-5",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }