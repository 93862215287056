var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.handleRightClick.apply(null, arguments)
        },
      },
    },
    [
      _vm.selectedImage
        ? _c("div", [
            _c(
              "div",
              { staticClass: "imageWrapper position-relative" },
              [
                _c("img", {
                  directives: [
                    {
                      name: "img",
                      rawName: "v-img",
                      value: _vm.selectedImage.full,
                      expression: "selectedImage.full",
                    },
                  ],
                  attrs: { src: _vm.selectedImage.full },
                }),
                _vm._v(" "),
                !_vm.isFullscreen
                  ? _c("b-icon", {
                      staticClass: "zoom-icon",
                      attrs: { icon: "arrows-angle-expand" },
                      on: { click: _vm.toggleFullscreen },
                    })
                  : _c("b-icon", {
                      staticClass: "zoom-icon",
                      attrs: { icon: "arrows-angle-contract" },
                      on: { click: _vm.toggleFullscreen },
                    }),
              ],
              1
            ),
          ])
        : _c("div", [_vm._v("\n    Loading...\n  ")]),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            {
              staticClass: "d-flex align-items-center",
              attrs: { justify: "space-between" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-start",
                  attrs: { cols: "6", sm: "12", md: "6" },
                },
                [
                  _c("small", { staticClass: "text-muted me-2" }, [
                    _vm._v("Publié " + _vm._s(_vm.selectedImage.createdAt)),
                  ]),
                  _vm._v(" "),
                  _vm.selectedImage.gallery
                    ? [
                        _c("small", { staticClass: "text-muted me-2" }, [
                          _vm._v(
                            "Galerie : " +
                              _vm._s(_vm.selectedImage.gallery.name)
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedImage.copyright
                    ? [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(
                            "Crédits photo : ©" +
                              _vm._s(_vm.selectedImage.copyright)
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "text-end",
                  attrs: { cols: "6", sm: "12", md: "6" },
                },
                [
                  _c("share-it", {
                    attrs: {
                      text: _vm.selectedImage.title,
                      targets: ["facebook", "twitter", "linkedin"],
                      dense: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", {
                staticClass: "text-start",
                domProps: { innerHTML: _vm._s(_vm.selectedImage.description) },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedImage.allowComments
        ? [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "text-start mb-md-4",
                        attrs: { cols: "12", sm: "12", md: "6" },
                      },
                      [
                        _c("div", { staticClass: "mb-4" }, [
                          _c("h4", { staticClass: "mb-0" }, [
                            _vm._v("Ajouter un commentaire"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Les commentaires sont soumis à une vérification avant d'être publiés."
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("FormulateForm", {
                              attrs: { "keep-model-data": true },
                              on: { submit: _vm.submitHandler },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ isLoading }) {
                                      return [
                                        _c("FormulateInput", {
                                          attrs: {
                                            type: "hidden",
                                            name: "imageId",
                                            value: _vm.selectedImage.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "double-wide" },
                                          [
                                            _c("FormulateInput", {
                                              attrs: {
                                                type: "text",
                                                name: "name",
                                                label: "Nom",
                                                validation: "required",
                                                "validation-messages": {
                                                  required:
                                                    "Merci de renseigner ce champs",
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("FormulateInput", {
                                              attrs: {
                                                type: "text",
                                                name: "city",
                                                label: "Ville",
                                                validation: "required",
                                                "validation-messages": {
                                                  required:
                                                    "Merci de renseigner ce champs",
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "double-wide" },
                                          [
                                            _c("FormulateInput", {
                                              attrs: {
                                                type: "text",
                                                name: "email",
                                                label: "Adresse e-mail",
                                                validation: "required|email",
                                                "validation-messages": {
                                                  required:
                                                    "Merci de renseigner ce champs",
                                                  email:
                                                    "Merci de renseigner une adresse e-mail valide",
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("FormulateInput", {
                                              attrs: {
                                                type: "url",
                                                name: "website",
                                                label: "Site web",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("FormulateInput", {
                                          attrs: {
                                            type: "textarea",
                                            name: "comment",
                                            label: "Message",
                                            validation: "required",
                                            "validation-messages": {
                                              required:
                                                "Merci de renseigner ce champs",
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("FormulateInput", {
                                          attrs: {
                                            type: "submit",
                                            disabled: isLoading,
                                            label: isLoading
                                              ? "Loading..."
                                              : "Envoyer",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3960294795
                              ),
                              model: {
                                value: _vm.formDatas,
                                callback: function ($$v) {
                                  _vm.formDatas = $$v
                                },
                                expression: "formDatas",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-start",
                        attrs: { cols: "12", sm: "12", md: "6" },
                      },
                      [
                        _c("h4", { staticClass: "mb-4" }, [
                          _vm._v(
                            "Commentaires (" +
                              _vm._s(_vm.selectedImage.countComments) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.selectedImage.countComments > 0
                          ? [
                              _c(
                                "ul",
                                { staticClass: "list-unstyled" },
                                _vm._l(_vm.comments, function (comment) {
                                  return _c(
                                    "li",
                                    { key: comment.id },
                                    [
                                      _c(
                                        "b-media",
                                        {
                                          staticClass: "d-flex mb-2",
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "aside",
                                                fn: function () {
                                                  return [
                                                    comment.website
                                                      ? [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: comment.website,
                                                                target:
                                                                  "_blank",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "vue-letter-avatar",
                                                                {
                                                                  attrs: {
                                                                    name: comment.name,
                                                                    size: "40",
                                                                    rounded: true,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : [
                                                          _c(
                                                            "vue-letter-avatar",
                                                            {
                                                              attrs: {
                                                                name: comment.name,
                                                                size: "40",
                                                                rounded: true,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-between",
                                            },
                                            [
                                              comment.website
                                                ? [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: comment.website,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "mt-0 text-primary",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                comment.name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticClass:
                                                          "mt-0 text-dark",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(comment.name)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                              _vm._v(" "),
                                              _c(
                                                "small",
                                                { staticClass: "text-muted" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(comment.city) +
                                                      " - " +
                                                      _vm._s(comment.createdAt)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("p", {}, [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(comment.text) +
                                                "\n                  "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ]
                          : [
                              _c(
                                "b-alert",
                                { attrs: { show: "", variant: "warning" } },
                                [_vm._v("Aucun commentaire pour l'instant.")]
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }