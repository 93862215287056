var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "blog-posts-grid-right-copy",
      attrs: { id: "w-node-_57fd8a3b-573b-0796-3ee0-acb067d21b50-83acdcf7" },
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.handleRightClick.apply(null, arguments)
        },
      },
    },
    [
      _c("go-top", {
        attrs: {
          "bg-color": "#1976d2",
          src: "/assets/img/icon-backtotop.png",
          size: 50,
          "has-outline": false,
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.feeds, function (feed, $index) {
        return _c("div", { staticClass: "services-hero-image-wrapper-copy" }, [
          _c("div", { staticClass: "div-block-26" }, [
            _c("div", { staticClass: "card muted full-height" }, [
              _c("div", { staticClass: "services-hero-card-inner" }, [
                _c("div", { staticClass: "div-block-29" }, [
                  _c(
                    "h5",
                    [
                      _c(
                        "a",
                        { attrs: { href: feed.user.url, target: "_blank" } },
                        [_vm._v(_vm._s(feed.user.firstname))]
                      ),
                      _vm._v(
                        " a publié " + _vm._s(feed.count) + "\n              "
                      ),
                      feed.count > 1
                        ? [_vm._v("nouvelles photos")]
                        : [_vm._v("nouvelle photo")],
                      _vm._v("\n              dans la galerie “"),
                      _c(
                        "a",
                        { attrs: { href: feed.gallery.url, target: "_blank" } },
                        [_vm._v(_vm._s(feed.gallery.name))]
                      ),
                      _vm._v("”\n            "),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom margin-xsmall" }, [
                    _c("div", { staticClass: "text-meta text-color-muted" }, [
                      _vm._v(_vm._s(feed.timeAgo)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "slider-3 w-slider",
                    attrs: {
                      id: "w-node-bb0e979a-e665-32c9-3f2b-e90462b9f6fa-83acdcf7",
                    },
                  },
                  [
                    feed.images
                      ? _c(
                          "div",
                          { staticClass: "about-team-grid-copy w-slider-mask" },
                          [
                            _c(
                              "VueSlickCarousel",
                              {
                                attrs: {
                                  arrows: true,
                                  dots: false,
                                  slidesToShow: 3,
                                  slidesToScroll: 1,
                                  infinite: false,
                                  responsive: [
                                    {
                                      breakpoint: 1024,
                                      settings: { slidesToShow: 1 },
                                    },
                                  ],
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prevArrow",
                                      fn: function (arrowOption) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "arrow-button w-slider-arrow-left",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "w-icon-slider-left",
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "nextArrow",
                                      fn: function (arrowOption) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "arrow-button right w-slider-arrow-right",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "w-icon-slider-right",
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _vm._v(" "),
                                _vm._l(feed.images, function (image, $index) {
                                  return [
                                    _c("v-img", {
                                      staticClass:
                                        "grey lighten-2 about-team-image-wrapper-copy w-slide me-5",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        src: image.path,
                                        "lazy-src": image.path,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openModal(image)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "placeholder",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass:
                                                      "fill-height ma-0",
                                                    attrs: {
                                                      align: "center",
                                                      justify: "center",
                                                    },
                                                  },
                                                  [
                                                    _c("v-progress-circular", {
                                                      attrs: {
                                                        indeterminate: "",
                                                        color: "grey lighten-5",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "div-block-32" }, [
                  _c("div", { staticClass: "div-block-34" }, [
                    _c("div", { staticClass: "post-author-grid" }, [
                      _c(
                        "a",
                        {
                          staticClass: "w-inline-block",
                          attrs: {
                            id: "w-node-f52d3bf7-62b7-b364-3d7b-789866cca89f-83acdcf7",
                            href: feed.user.url,
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "post-author-image xlarge",
                            attrs: {
                              loading: "lazy",
                              id: "w-node-f52d3bf7-62b7-b364-3d7b-789866cca8a0-83acdcf7",
                              alt: "",
                              src: feed.user.avatar,
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "div-block-31",
                          attrs: {
                            id: "w-node-f52d3bf7-62b7-b364-3d7b-789866cca8a1-83acdcf7",
                          },
                        },
                        [
                          _c("div", { staticClass: "margin-bottom" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "margin-top w-inline-block identity-wrapper",
                                attrs: {
                                  href: feed.user.url,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("div", { staticClass: "div-block-6" }, [
                                  _c("h4", { staticClass: "heading-h4" }, [
                                    _vm._v(_vm._s(feed.user.fullName)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                feed.user.isPro ? [_vm._m(0, true)] : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("h6", { staticClass: "margin-top" }, [
                              _vm._v(_vm._s(feed.user.profession)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-size-small" }, [
                              _vm._v(_vm._s(feed.user.address.fullAddress)),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button secondary w-inline-block",
                      attrs: { href: feed.gallery.url, target: "_blank" },
                    },
                    [
                      _c("div", { staticClass: "button-inner" }, [
                        _c("div", { staticClass: "button-inner-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("message.all_photos", _vm.locale_request)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "button-inner-text-hover" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("message.all_photos", _vm.locale_request)
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      }),
      _vm._v(" "),
      _c(
        "infinite-loading",
        {
          attrs: { identifier: _vm.infiniteId },
          on: { infinite: _vm.infiniteHandler },
        },
        [
          _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
          _vm._v(" "),
          _c("div", { attrs: { slot: "no-results" }, slot: "no-results" }, [
            _c("div", { staticClass: "background-color-muted w-dyn-empty" }, [
              _c("div", [_vm._v("No results")]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "imageModal",
          attrs: {
            id: "scroll",
            scrollable: "",
            centered: "",
            size: "xl",
            "hide-footer": "",
          },
          on: { hide: _vm.handleModalClose },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "close-btn",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.imageModal.hide()
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "x",
                          "aria-label": "Close",
                          variant: "dark",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "space-between" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-start",
                              attrs: {
                                cols: _vm.getTitleCols(),
                                sm: "12",
                                md: _vm.getTitleCols(),
                              },
                            },
                            [
                              _c(
                                "b-media",
                                {
                                  staticClass: "d-flex",
                                  scopedSlots: _vm._u([
                                    {
                                      key: "aside",
                                      fn: function () {
                                        return [
                                          _c("b-avatar", {
                                            attrs: {
                                              button: "",
                                              rounded: "sm",
                                              src: _vm.selectedImage.user
                                                .avatar,
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("div", { staticClass: "lh-sm" }, [
                                    _c(
                                      "h5",
                                      { staticClass: "m-0 fs-6 lh-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedImage.user.fullName
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "m-0 text-muted" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedImage.user.profession
                                              .title
                                          ) +
                                            " - " +
                                            _vm._s(
                                              _vm.selectedImage.user.address
                                                .fullAddress
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.selectedImage.title
                            ? [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { "white-space": "nowrap" },
                                    attrs: {
                                      cols: _vm.getTitleCols(),
                                      sm: "12",
                                      md: _vm.getTitleCols(),
                                    },
                                  },
                                  [
                                    _c("h5", { staticClass: "m-0 p-0 fs-6" }, [
                                      _vm._v(_vm._s(_vm.selectedImage.title)),
                                    ]),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-end",
                              attrs: {
                                cols: _vm.getTitleCols(),
                                sm: "12",
                                md: _vm.getTitleCols(),
                              },
                            },
                            [
                              _vm.selectedImage.allowComments
                                ? [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "light" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getAnchorLink(
                                              "imageDown"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "me-1",
                                          attrs: {
                                            icon: "chat-left-dots",
                                            "font-scale": "0.9",
                                            "aria-label": "Like",
                                            variant: "dark",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.selectedImage.countComments
                                            ) +
                                            "\n                "
                                        ),
                                        _vm.selectedImage.countComments > 1
                                          ? [_vm._v("commentaires")]
                                          : [_vm._v("commentaire")],
                                      ],
                                      2
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "light" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getAnchorLink("imageDown")
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "me-1",
                                    attrs: {
                                      icon: "info-circle",
                                      "font-scale": "0.9",
                                      "aria-label": "Like",
                                      variant: "dark",
                                    },
                                  }),
                                  _vm._v("\n              Infos\n            "),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("viewer-image-common", {
            attrs: { selectedImage: _vm.selectedImage },
          }),
          _vm._v(" "),
          _c("div", { attrs: { id: "scroll-imageDown" } }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "label primary small" }, [
      _c("div", [_vm._v("Pro")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }