<template>
  <div>
    <go-top
        bg-color="#1976d2"
        src="/assets/img/icon-backtotop.png"
        :size="50"
        :has-outline="false"
    ></go-top>
    <section class="section-home-hero">
      <div class="page-padding">
        <div class="padding-top padding-huge">
          <div class="padding-bottom padding-xhuge">
            <div class="container-xlarge">
              <div class="product-hero-grid large">
                <div id="w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f71b-1f0c80fd" class="product-hero-grid-right">
                  <div data-w-id="1ca4e8a3-8819-7a33-b28a-35e3d813f71c"
                       style="-webkit-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                       class="card muted">
                    <div class="card-padding small">
                      <div class="margin-bottom margin-xsmall">
                        <h4 class="margin-bottom margin-medium">{{ $t('message.availabilities.refine_your_search', locale_request) }}</h4>
                      </div>
                      <div class="margin-bottom margin-medium">
                        <div data-w-id="8f9836ef-040c-bb3d-e095-8b19e7622ddb"
                             style="-webkit-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                             class="form-wrapper w-form">
                          <div class="form-field-wrapper">
                            <FormulateInput
                                :label="$t('message.availabilities.what_type_of_artist_are_you_looking_for', locale_request)"
                                name="job"
                                v-model="job"
                                class="m0-20"
                                type="select"
                                :options="jobList"
                                :placeholder="$t('message.availabilities.select_artist_type', locale_request)"
                                help-class="form-text"
                                label-class="form-label"
                                input-class="form-input w-select"
                                @change="changeType"
                            />
                          </div>
                          <div class="form-field-wrapper">
                            <FormulateInput
                                :label="$t('message.availabilities.what_opportunities_do_you_offer', locale_request)"
                                name="contract"
                                v-model="contract"
                                class="m0-20"
                                type="select"
                                :options="{collab: 'Une collaboration', freelance: 'Du freelance', job: 'Un emploi à temps plein'}"
                                :placeholder="$t('message.availabilities.select_opportunity', locale_request)"
                                help-class="form-text"
                                label-class="form-label"
                                input-class="form-input w-select"
                                @change="changeType"
                            />
                          </div>
                          <div class="form-field-wrapper">
                            <FormulateInput
                                class="m0-20"
                                type="select"
                                v-model="collab"
                                v-if="contract === 'collab'"
                                :options="{paid: 'Rémunérée', free: 'Gratuite'}"
                                name="type_collaboration"
                                :placeholder="$t('message.availabilities.make_a_choice', locale_request)"
                                :label="$t('message.availabilities.is_it_paid_or_free', locale_request)"
                                help-class="form-text"
                                label-class="form-label"
                                @change="changeType"
                                input-class="form-input w-input"
                            />
                          </div>
                          <div class="form-field-wrapper">
                            <FormulateInput
                                :label="$t('message.availabilities.in_which_city', locale_request)"
                                name="cities"
                                v-model="cities"
                                class="m0-20"
                                type="text"
                                placeholder="Ex: Paris, Bordeaux, etc."
                                help-class="form-text"
                                label-class="form-label"
                                input-class="form-input w-input"
                                @change="changeType"
                            />
                            <FormulateInput
                                :label="$t('message.availabilities.for_which_date', locale_request)"
                                name="dateStart"
                                v-model="dateStart"
                                class="m0-20"
                                type="date"
                                help-class="form-text"
                                label-class="form-label"
                                input-class="form-input w-input"
                                @change="changeType"
                                :min="getMinDate()"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f725-1f0c80fd" class="product-hero-grid-left">
                  <h2 class="margin-bottom">{{ $t('message.availabilities.find_perfect_talent_for_event', locale_request)}}</h2>
                  <p class="text-size-regular">{{ $t('message.availabilities.discover_current_artist_availabilities', locale_request)}}</p>
                  <h5 class="margin-bottom">{{ $t('message.availabilities.what_about_you_what_are_your_availabilities', locale_request)}}</h5>
                  <div>
                    <a href="dashboard/work-availability/" class="button secondary w-inline-block">
                      <div class="button-inner">
                        <div class="button-inner-text">{{ $t('message.availabilities.add_your_availabilities', locale_request)}}</div>
                        <div class="button-inner-text-hover">{{ $t('message.availabilities.add_your_availabilities', locale_request)}}</div>
                      </div>
                    </a>
                  </div>
                  <div class="w-dyn-list">
                    <div role="list" class="availabilities-grid w-dyn-items">
                      <div v-for="(availability, $index) in availabilities"
                           :key="$index" role="listitem" class="w-dyn-item">
                        <div class="margin-top margin-small">
                          <div data-w-id="b05ee670-0585-6fbd-18ed-fd52d7478990"
                               class="card muted">
                            <div class="card-padding">
                              <div class="margin-bottom margin-small">
                                <h3 class="margin-bottom margin-small">
                                  <a :href="availability.url" target="_blank">{{
                                      availability.locations
                                    }}</a>
                                </h3>
                                <h5 class="margin-bottom margin-tiny">{{ $t('message.availabilities.from_date', locale_request)}} {{ availability.dateStart }} {{ $t('message.availabilities.to', locale_request)}}
                                  {{ availability.dateEnd }}</h5>
                                <h6>{{ $t('message.availabilities.project_type', locale_request)}} :
                                  <template v-if="availability.contract == 'collab'">Collaboration
                                    <template v-if="availability.collab">
                                      <template v-if="availability.collab == 'paid'">
                                        ({{ $t('message.availabilities.paid', locale_request)}})
                                      </template>
                                      <template v-else>
                                        ({{ $t('message.availabilities.free', locale_request)}})
                                      </template>
                                    </template>
                                  </template>
                                  <template v-else-if="availability.contract == 'job'">Job</template>
                                  <template v-else>Freelance</template>
                                </h6>
                              </div>
                              <div class="margin-top margin-small">
                                <div class="post-author-grid">
                                  <a id="w-node-_0afac987-75a4-e398-b077-bcfa4f22ad3d-1f0c80fd" :href="availability.url"
                                     target="_blank"
                                     class="w-inline-block"><img loading="lazy"
                                                                 id="w-node-b05ee670-0585-6fbd-18ed-fd52d74789a4-1f0c80fd"
                                                                 :src="availability.user.avatar" alt=""
                                                                 class="post-author-image"></a>
                                  <div id="w-node-b05ee670-0585-6fbd-18ed-fd52d74789a5-1f0c80fd">
                                    <div class="margin-bottom margin-tiny">
                                      <a :href="availability.url" target="_blank" class="w-inline-block">
                                        <h4 class="heading-h5">{{ availability.user.fullName }}</h4>
                                      </a>
                                    </div>
                                    <div class="text-meta-small text-color-muted">{{ availability.user.profession }} •
                                      {{
                                        availability.user.location
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="margin-top margin-medium">
                                <a :href="availability.url" target="_blank" class="button w-inline-block">
                                  <div class="button-inner">
                                    <div class="button-inner-text">{{ $t('message.availabilities.learn_more', locale_request)}}</div>
                                    <div class="button-inner-text-hover">{{ $t('message.availabilities.learn_more', locale_request)}}</div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <infinite-loading
                          :identifier="infiniteId"
                          @infinite="infiniteHandler"
                      >
                        <div slot="no-more"></div>
                        <div slot="no-results">
                          <div class="background-color-muted w-dyn-empty">
                            <div>{{ $t('message.availabilities.no_availabilities_at_the_moment', locale_request)}}</div>
                          </div>
                        </div>
                      </infinite-loading>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

const routes = require("../../../../public/js/fos_js_routes.json");
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import job_options from "../dashboard/datas/job_options_array.json";

Routing.setRoutingData(routes);

export default {
  components: {},
  data: function () {
    return {
      page: 1,
      availabilities: [],
      infiniteId: +new Date(),
      job: null,
      contract: "",
      cities: null,
      collab: null,
      dateStart: null,
      dateEnd: null,
      urlAdd: this.urlAdd,
      locale_request: null,
      job_options: job_options,
    };
  },
  mounted: function () {
    this.locale_request = $('html').attr('lang');
  },
  computed: {
    jobList: function () {
      return this.job_options;
    },
    availabilityText() {
      if (this.availability.collab === "paid") {
        return "(Rémunérée)";
      } else {
        return "(Gratuite)";
      }
    }
  },
  watch: {},
  methods: {
    getMinDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    infiniteHandler($state) {
      this.$http
          .get(
              Routing.generate("json_availabilities", {
                page: this.page,
              }),
              {
                params: {
                  page: this.page,
                  contract: this.contract,
                  collab: this.collab,
                  job: this.job,
                  cities: this.cities,
                  dateStart: this.dateStart,
                  dateEnd: this.dateEnd,
                },
              }
          )
          .then(({data}) => {
            if (data.availabilities.length > 0) {
              this.page += 1;
              this.availabilities.push(...data.availabilities);
              if (this.page > data.totalPages) {
                $state.complete();
              }
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },
    changeType() {
      this.page = 1;
      this.availabilities = [];
      this.infiniteId += 1;
    },
  },
};
</script>