<template>
  <div>
    <template v-if="isCompleted">
      <button class="button-small margin-none full like w-inline-block vote-number icon vote-button"
              style="padding-top: 15px;" disabled>
        <i class="fas fa-heart"></i>
        <div class="button-label bold like-text" style="margin-left: 0px;">{{ countVotes }}</div>
      </button>
    </template>
    <template v-else>
      <button class="button-small margin-none full like w-inline-block vote-number icon vote-button"
              style="padding-top: 15px;" @click="addVote" :disabled="isLoading=='true'">
        <i v-if="isLoading == 'true'" class="fa fa-spinner fa-spin"></i>
        <i v-else>
    <span v-if="showHeart !== 'true'" class="fa-stack">
      <i class="far fa-heart fa-stack-1x"></i>
      <i v-if="localLiked" class="fas fa-heart fa-stack-1x liked"></i>
    </span>
          <i v-else class="fas fa-heart"></i>
        </i>
        <div class="button-label bold like-text" style="margin-left: 0px;">{{ countVotes }}</div>
        <notifications position="bottom center"/>
      </button>
    </template>
  </div>
</template>

<script>

import axios from "axios";
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../public/js/fos_js_routes.json");

Routing.setRoutingData(routes);

export default {
  props: ["countVotes", "feedbackId", "isLoading", "liked", "isOwner", "showHeart", "isUserLoggedIn", "isCompleted"],
  data() {
    return {
      localLiked: this.liked,
      localIsLoading: this.isLoading,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
    },
    addVote() {
      if (!this.isUserLoggedIn) {
        this.$notify({
          type: 'error',
          text: 'Vous devez être connecté pour voter',
          duration: 5000, // durée en millisecondes
          speed: 1000, // vitesse de transition en millisecondes
          showClose: true, // afficher le bouton fermer
        })
        return;
      }

      if (this.isOwner) {
        this.$notify({
          type: 'error',
          text: 'Vous ne pouvez pas voter pour votre propre idée.',
          duration: 5000, // durée en millisecondes
          speed: 1000, // vitesse de transition en millisecondes
          showClose: true, // afficher le bouton fermer
        })
        return
      }

      this.localIsLoading = true;
      axios.post(Routing.generate("suggestion_box_votes", {feedbackId: this.feedbackId}))
          .then(response => {
            if (response.status === 200 && response.data.success === true) {
              this.countVotes += 1;
              this.showHeart = true;
              this.localLiked = true; // utilisez la variable de données localLiked ici
            } else {
              console.error('Erreur lors de l\'envoi du vote : ' + response.status);
            }
          })
          .catch(error => {
            console.error('Erreur lors de l\'envoi du vote : ' + error);
          })
          .finally(() => {
            this.localIsLoading = false;
          });
    },
  },
};
</script>
