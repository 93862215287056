<template>
  <div class="w-dyn-list">
    <div role="list" class="product3-grid w-dyn-items">
      <div id="w-node-d1877772-0e2a-05d9-54af-018be7b559c7-87e111a3" v-for="(image, $index) in images"
           :key="$index" role="listitem" class="w-dyn-item">
        <div class="image-wrap">
          <div class="product-image product-image-size-l">
            <v-img
                v-on:click="setView(image.id)"
                :aspect-ratio="3 / 4"
                :width="width"
                class="grey lighten-2 product-image product-image-size-l"
                v-img="{ src: image.path, title: image.title }"
                :alt="image.title"
                :src="image.thumb_path"
                :lazy-src="image.thumb_path"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="label white-label">
              <a :href="image.user.url" target="_blank">{{
                  image.user.fullname
                }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const routes = require("../../../../public/js/fos_js_routes.json");
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

Routing.setRoutingData(routes);

export default {
  components: {},
  data: function () {
    return {
      page: 1,
      images: [],
      width: 600,
    };
  },
  mounted() {
    this.$http
        .get(
            Routing.generate("front_images_home", {
              page: this.page,
            })
        )
        .then(function (response) {
          this.images = response.data;
        });
  },
  methods: {
    setView: function (id) {
      $.ajax({
        url: Routing.generate("portfolio_setviewmedias", {
          name: name,
          image: id,
        }),
        type: "POST",
        data: id,
        success: function (response) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(textStatus, errorThrown);
        },
      });
    },
  },
};
</script>