var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-dyn-list" }, [
    _c(
      "div",
      { staticClass: "product3-grid w-dyn-items", attrs: { role: "list" } },
      _vm._l(_vm.images, function (image, $index) {
        return _c(
          "div",
          {
            key: $index,
            staticClass: "w-dyn-item",
            attrs: {
              id: "w-node-d1877772-0e2a-05d9-54af-018be7b559c7-87e111a3",
              role: "listitem",
            },
          },
          [
            _c("div", { staticClass: "image-wrap" }, [
              _c(
                "div",
                { staticClass: "product-image product-image-size-l" },
                [
                  _c("v-img", {
                    directives: [
                      {
                        name: "img",
                        rawName: "v-img",
                        value: { src: image.path, title: image.title },
                        expression: "{ src: image.path, title: image.title }",
                      },
                    ],
                    staticClass:
                      "grey lighten-2 product-image product-image-size-l",
                    attrs: {
                      "aspect-ratio": 3 / 4,
                      width: _vm.width,
                      alt: image.title,
                      src: image.thumb_path,
                      "lazy-src": image.thumb_path,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setView(image.id)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "placeholder",
                          fn: function () {
                            return [
                              _c(
                                "v-row",
                                {
                                  staticClass: "fill-height ma-0",
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "grey lighten-5",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "label white-label" }, [
                    _c(
                      "a",
                      { attrs: { href: image.user.url, target: "_blank" } },
                      [_vm._v(_vm._s(image.user.fullname))]
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }