var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isCompleted
        ? [
            _c(
              "button",
              {
                staticClass:
                  "button-small margin-none full like w-inline-block vote-number icon vote-button",
                staticStyle: { "padding-top": "15px" },
                attrs: { disabled: "" },
              },
              [
                _c("i", { staticClass: "fas fa-heart" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "button-label bold like-text",
                    staticStyle: { "margin-left": "0px" },
                  },
                  [_vm._v(_vm._s(_vm.countVotes))]
                ),
              ]
            ),
          ]
        : [
            _c(
              "button",
              {
                staticClass:
                  "button-small margin-none full like w-inline-block vote-number icon vote-button",
                staticStyle: { "padding-top": "15px" },
                attrs: { disabled: _vm.isLoading == "true" },
                on: { click: _vm.addVote },
              },
              [
                _vm.isLoading == "true"
                  ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                  : _c("i", [
                      _vm.showHeart !== "true"
                        ? _c("span", { staticClass: "fa-stack" }, [
                            _c("i", {
                              staticClass: "far fa-heart fa-stack-1x",
                            }),
                            _vm._v(" "),
                            _vm.localLiked
                              ? _c("i", {
                                  staticClass: "fas fa-heart fa-stack-1x liked",
                                })
                              : _vm._e(),
                          ])
                        : _c("i", { staticClass: "fas fa-heart" }),
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "button-label bold like-text",
                    staticStyle: { "margin-left": "0px" },
                  },
                  [_vm._v(_vm._s(_vm.countVotes))]
                ),
                _vm._v(" "),
                _c("notifications", { attrs: { position: "bottom center" } }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }