<template>
  <div>
    <go-top
        bg-color="#1976d2"
        src="/assets/img/icon-backtotop.png"
        :size="50"
        :has-outline="false"
    ></go-top>
    <section class="section-home-hero">
      <div class="page-padding">
        <div class="padding-top padding-huge">
          <div class="padding-bottom padding-xhuge">
            <div class="container-xlarge">
              <div class="product-hero-grid large">
                <div id="w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f71b-4ffa9efb" class="product-hero-grid-right">
                  <div data-w-id="1ca4e8a3-8819-7a33-b28a-35e3d813f71c" class="card muted">
                    <div class="card-padding small">
                      <div class="margin-bottom margin-xsmall">
                        <h4 class="margin-bottom margin-medium">{{ $t('message.ads.search.title', locale) }}</h4>
                      </div>
                      <div class="margin-bottom margin-medium">
                        <div data-w-id="8f9836ef-040c-bb3d-e095-8b19e7622ddb" class="form-wrapper w-form">
                          <form id="contact-form" name="wf-form-Contact-Form" data-name="Contact Form" method="get"
                                class="form" data-wf-page-id="64cc4c052f70d1454ffa9efb"
                                data-wf-element-id="8f9836ef-040c-bb3d-e095-8b19e7622ddc">
                            <div class="form-field-wrapper">
                              <div class="form-label">{{ $t('message.ads.search.fields.job.label', locale) }}</div>
                              <v-select
                                  :items="professionList"
                                  :clearable="true"
                                  item-value="id"
                                  item-text="title"
                                  v-model="profession"
                                  label="Sélectionner"
                                  @change="changeType"
                                  rounded
                                  attach
                                  single-line
                                  class="form-input w-select"
                              ></v-select>
                            </div>
                            <div class="form-field-wrapper">
                              <div class="form-label">{{ $t('message.ads.search.fields.sex.label', locale) }}</div>
                              <v-select
                                  v-model="sex"
                                  :items="sexList"
                                  clearable
                                  hide-selected
                                  item-text="title"
                                  item-value="id"
                                  label="Sélectionner"
                                  @change="changeType"
                                  rounded
                                  single-line
                                  class="form-input w-select"
                                  attach
                              >
                              </v-select>
                            </div>
                            <div class="form-field-wrapper">
                              <div class="form-label">{{
                                  $t('message.ads.search.fields.prestation.label', locale)
                                }}
                              </div>
                              <v-select
                                  v-model="prestation"
                                  :items="prestationList"
                                  clearable
                                  hide-selected
                                  item-text="title"
                                  item-value="id"
                                  label="Sélectionner"
                                  @change="changeType"
                                  rounded
                                  single-line
                                  class="form-input w-select"
                                  attach
                              >
                              </v-select>
                            </div>
                            <div class="form-field-wrapper">
                              <div class="form-label">{{ $t('message.ads.search.fields.location.label', locale) }}</div>
                              <v-autocomplete
                                  v-model="autocomplete"
                                  :items="items"
                                  :loading="isLoading"
                                  :search-input.sync="search"
                                  clearable
                                  hide-selected
                                  item-text="name"
                                  item-value="symbol"
                                  label="Sélectionner"
                                  @change="changeType"
                                  rounded
                                  single-line
                                  class="form-input w-select"
                                  attach
                              >
                                <template slot="no-data">
                                  {{ $t('message.ads.search.fields.location.placeHolder', locale) }}
                                </template>
                              </v-autocomplete>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f725-4ffa9efb" class="product-hero-grid-left">
                  <h2 class="margin-bottom">{{ $t('message.ads.head.title', locale) }}</h2>
                  <p class="text-size-regular">{{ $t('message.ads.head.content', locale) }}</p>
                  <div>
                    <a :href="urlAdd" class="button secondary w-inline-block">
                      <div class="button-inner">
                        <div class="button-inner-text">{{ $t('message.ads.head.buttons.add', locale) }}</div>
                        <div class="button-inner-text-hover">{{ $t('message.ads.head.buttons.add', locale) }}</div>
                      </div>
                    </a>
                  </div>
                  <div class="w-dyn-list">
                    <div role="list" class="availabilities-grid w-dyn-items">
                      <div role="listitem" class="w-dyn-item" v-for="(annonce, $index) in annonces"
                           :key="$index">
                        <div class="margin-top margin-small">
                          <div data-w-id="b05ee670-0585-6fbd-18ed-fd52d7478990" class="card muted">
                            <div class="card-padding">
                              <div class="margin-bottom margin-small">
                                <h3 class="margin-bottom margin-small">
                                  <a :href="annonce.url">{{ annonce.title }}</a>
                                </h3>
                                <h5 class="margin-bottom margin-tiny">{{ $t('message.for_text', locale) }} {{ annonce.profession }} {{ $t('message.gender', locale) }} :
                                  {{ annonce.gender }}</h5>
                                <h6>{{ $t('message.service_type', locale) }} : {{ annonce.prestation }}</h6>
                                <p class="text-size-regular">{{ $t('message.location', locale) }} : {{ annonce.location }}</p>
                                <p class="text-size-small">{{ annonce.date }}</p>
                              </div>
                              <div class="margin-top margin-small">
                                <div class="post-author-grid">
                                  <a id="w-node-_0afac987-75a4-e398-b077-bcfa4f22ad3d-4ffa9efb" :href="annonce.user.url"
                                     target="_blank"
                                     class="w-inline-block"><img loading="lazy"
                                                                 id="w-node-b05ee670-0585-6fbd-18ed-fd52d74789a4-4ffa9efb"
                                                                 :src="annonce.user.avatar" alt=""
                                                                 class="post-author-image"></a>
                                  <div id="w-node-b05ee670-0585-6fbd-18ed-fd52d74789a5-4ffa9efb">
                                    <div class="margin-bottom margin-tiny">
                                      <a :href="annonce.user.url" target="_blank" class="w-inline-block">
                                        <h4 class="heading-h5">{{ annonce.user.fullName }}</h4>
                                      </a>
                                    </div>
                                    <div class="text-meta-small text-color-muted">{{ annonce.user.profession }} •
                                      {{ annonce.user.location }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="margin-top margin-medium">
                                <a :href="annonce.url" class="button w-inline-block">
                                  <div class="button-inner">
                                    <div class="button-inner-text">{{
                                        $t('message.ads.head.buttons.read', locale)
                                      }}
                                    </div>
                                    <div class="button-inner-text-hover">{{
                                        $t('message.ads.head.buttons.read', locale)
                                      }}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <infinite-loading
                          :identifier="infiniteId"
                          @infinite="infiniteHandler"
                      >
                        <div slot="no-more"></div>
                        <div slot="no-results">
                          <div class="background-color-muted w-dyn-empty">
                            <div> {{ $t('message.ads.search.notFound', locale) }}</div>
                          </div>
                        </div>
                      </infinite-loading>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

const routes = require("../../../../public/js/fos_js_routes.json");
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

Routing.setRoutingData(routes);

export default {
  components: {},
  data: function () {
    return {
      page: 1,
      annonces: [],
      infiniteId: +new Date(),

      profession: "",
      professionList: [],
      prestation: "",
      prestationList: [],
      sexList: [],
      sex: "",
      autocomplete: null,

      disabled: true,
      isLoading: false,
      items: [],
      search: null,
      urlAdd: null,
      defaultOrder: "updatedAt",
      sortList: [],
      componentForm: {
        street_number: "",
        route: "",
        locality: "",
        administrative_area_level_1: "",
        country: "",
        postal_code: "",
      },
      locale: null,
    };
  },
  mounted: function () {
    this.locale = $('html').attr('lang');
  },
  watch: {
    search(val) {
      if (!val) {
        return;
      }
      this.isLoading = true;
      const service = new google.maps.places.AutocompleteService();
      service.getQueryPredictions({input: val}, (predictions, status) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) {
          return;
        }
        this.items = predictions.map((prediction) => {
          return {
            id: prediction.id,
            name: prediction.description,
          };
        });
        this.isLoading = false;
      });
    },
  },
  methods: {
    infiniteHandler($state) {
      this.$http
          .get(
              Routing.generate("json_announces", {
                page: this.page,
              }),
              {
                params: {
                  page: this.page,
                  profession: this.profession,
                  sex: this.sex,
                  prestation: this.prestation,
                  sortBy: this.defaultOrder,
                  autocomplete: this.autocomplete,
                },
              }
          )
          .then(({data}) => {
            if (data.annonces.length > 0) {
              this.page += 1;
              this.annonces.push(...data.annonces);
              if (this.page > data.totalPages) {
                $state.complete();
              }
              this.professionList = data.professionList;
              this.sexList = data.sexList;
              this.prestationList = data.prestationList;
              this.sortList = data.sortList;
              this.urlAdd = data.urlAdd;
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },
    changeType() {
      this.page = 1;
      this.annonces = [];
      this.infiniteId += 1;
    },
  },
};
</script>