var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("go-top", {
        attrs: {
          "bg-color": "#1976d2",
          src: "/assets/img/icon-backtotop.png",
          size: 50,
          "has-outline": false,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "padding-top" }, [
        _c("div", { staticClass: "container-xlarge" }, [
          _c(
            "div",
            {
              staticClass: "product-hero-grid-left",
              attrs: {
                id: "w-node-_1ca4e8a3-8819-7a33-b28a-35e3d813f725-88ee08b8",
              },
            },
            [
              _c("div", { staticClass: "w-dyn-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "annuaire-grid w-dyn-items",
                    attrs: { role: "list" },
                  },
                  [
                    _vm._l(_vm.users, function (user, $index) {
                      return _c(
                        "div",
                        {
                          key: $index,
                          staticClass: "w-dyn-item",
                          attrs: { role: "listitem" },
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: {
                                id: "w-node-_9cd56d24-5299-eafa-2c62-048babbe4559-88ee08b8",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "div-block-7" },
                                [
                                  user.identity.isPro
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "div-block-8" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "label primary" },
                                              [_c("div", [_vm._v("Pro")])]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link-block w-inline-block",
                                      attrs: {
                                        href: user.identity.url,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "profile-picture",
                                        staticStyle: {
                                          "-webkit-transform":
                                            "translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                          "-moz-transform":
                                            "translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                          "-ms-transform":
                                            "translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                          transform:
                                            "translate3d(0, 0, 0) scale3d(1.1, 1.1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                        },
                                        style: {
                                          backgroundImage: `url(${user.identity.avatar})`,
                                        },
                                        attrs: {
                                          id: "w-node-f31acf5e-d582-cb4b-8609-3b42aa06e293-88ee08b8",
                                          "data-w-id":
                                            "f31acf5e-d582-cb4b-8609-3b42aa06e293",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  user.identity.availabilities.length > 0
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "div-block-5" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "label muted",
                                                attrs: {
                                                  href: user.identity
                                                    .link_availability,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      user.identity
                                                        .availabilities.length
                                                    ) + " disponibilité(s)"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "margin-top margin-small w-inline-block",
                                  attrs: {
                                    href: user.identity.url,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "div-block-6" }, [
                                    _c("h4", { staticClass: "heading-h4" }, [
                                      _vm._v(_vm._s(user.identity.fullName)),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("h6", { staticClass: "margin-top" }, [
                                _vm._v(
                                  _vm._s(_vm.$t(user.identity.experience, "fr"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-size-small" }, [
                                _vm._v(_vm._s(user.identity.location)),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "infinite-loading",
                      {
                        attrs: { identifier: _vm.infiniteId },
                        on: { infinite: _vm.infiniteHandler },
                      },
                      [
                        _c("div", {
                          attrs: { slot: "no-more" },
                          slot: "no-more",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "no-results" }, slot: "no-results" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "background-color-muted w-dyn-empty",
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "message.directory.filters.empty",
                                        "fr"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }