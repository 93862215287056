import { render, staticRenderFns } from "./inspirations.vue?vue&type=template&id=09229d6c"
import script from "./inspirations.vue?vue&type=script&lang=js"
export * from "./inspirations.vue?vue&type=script&lang=js"
import style0 from "./inspirations.vue?vue&type=style&index=0&id=09229d6c&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/merdy/Sites/Symfony/bookfolio/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('09229d6c')) {
      api.createRecord('09229d6c', component.options)
    } else {
      api.reload('09229d6c', component.options)
    }
    module.hot.accept("./inspirations.vue?vue&type=template&id=09229d6c", function () {
      api.rerender('09229d6c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/vuejs/components/front/inspirations.vue"
export default component.exports